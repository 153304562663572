import PropTypes from "prop-types";
import React from "react";

export default function Li({children}) {
    return(
      <li className="my-4">
          {children}
      </li>
    )}
  
  
Li.propTypes = {
    children: PropTypes.node.isRequired,
};