import React from "react";
import { Link } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import MainHeading from "../components/main-heading";
import ImageText from "../components/image-text";
import TextImage from "../components/text-image";
import Li from "../components/li";
import UiLink from "../components/ui-link";
import UiLinkContainer from "../components/ui-link-container";

import mainImg from "../images/undraw_web_search_eetr.svg";
import vyhledavaniImg from "../images/vyhledavani.jpg";
import supportIcon from "../images/icons/support.svg";

function VyhledavaniInformaciPage() {
    return (
      <Layout>
          <SEO
        title="Jak vyhledávat informace"
      />
        <Section>
            <MainHeading heading="Jak vyhledávat informace" section="Rozcestník">
            </MainHeading>
            <ImageText image={mainImg}>
              <p className="text-xl sm:text-3xl text-gray-600 font-bold text-center mx-4 my-8 lg:mx-16">Vyhledávání informací: <br />vaše nejužitečnější dovednost ve světě internetu.</p>
            </ImageText>
            <TextImage image={vyhledavaniImg}>
            <ol className="text-lg sm:text-xl">
              <Li>
                <p>V <a className="font-semibold hover:underline" href="https://www.facebook.com/groups/PCporadna.net/announcements" rel="noopener noreferrer">oznámení skupiny</a> můžete najít aktuální informace a problémy.</p>
              </Li>
              <Li>
                <p>Prohlédněte si <Link to="/faq/" className="font-semibold hover:underline">často kladené otázky</Link>.</p>
              </Li>
              <Li>
                <p>Zkuste vyhledávání ve skupině. 🔍 Lupu najdete na počítači v levém sloupečku pod sekcemi skupiny a na mobilu v pravém horním rohu.</p>
              </Li>
              <Li>
                <p>Dobrá volba je také Google. Zde můžete vyhledat odpovědi na téměř všechny dotazy. Je to jednoduché a ušetříte nejen sobě spoustu času 🙂.</p>
              </Li>
            </ol>
            <p className="my-4 w-full">Tento postup je určitou internetovou etiketou a považujeme jej za samozřejmost a slušnost vůči ostatním členům naší skupiny.</p>
            <div className="w-128 w-full sm:w-4/6">
                <UiLinkContainer>
                    <UiLink 
                    headerText="Nedaří se vám najít co potřebujete?"
                    descriptionText="Pomůžeme vám!"
                    link="/zalozeni-dotazu/"
                    image={supportIcon}
                    />
                </UiLinkContainer>
            </div>
            </TextImage>
            
            
        </Section>

      </Layout>

      );
    }
    
export default VyhledavaniInformaciPage;
