import PropTypes from "prop-types";
import React from "react";

export default function Section({ children }) {
  return(
    <section className="text-gray-700 body-font">
        <div className="container mx-auto">
            {children}
        </div>
    </section>
  )}

  Section.propTypes = {
    children: PropTypes.node.isRequired,
  };