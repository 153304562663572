import PropTypes from "prop-types";
import React from "react";

export default function UiLinkContainer({children}) {
    return(
      <div className="flex flex-col rounded-lg bg-white max-w-screen-sm shadow-sm my-4 overflow-hidden m-auto">
          {children}
      </div>
    )}
  
  
UiLinkContainer.propTypes = {
    children: PropTypes.node.isRequired,
};