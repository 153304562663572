import React from "react";
import PropTypes from "prop-types";

export default function TextImage(props) {
    return<div className="flex flex-col lg:flex-row-reverse items-center mb-8">
            <div className="w-5/6 sm:w-1/2 lg:w-2/6 self-center m-4">
                <img className="rounded-lg" src={props.image} />
            </div>
            <div className="flex flex-wrap justify-center items-center w-full lg:w-4/6">
            {props.children}
            </div>
          </div>
  }
  
  TextImage.defaultProps = {
    image: `https://picsum.photos/1200/500`
  };
  
  TextImage.propTypes = {
  image: PropTypes.string,
  children: PropTypes.node.isRequired
  };