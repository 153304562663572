import React from "react";
import PropTypes from "prop-types";

export default function ImageText(props) {
  return<div className="flex flex-col lg:flex-row items-center mb-8">
          <div className="w-5/6 sm:w-1/2 lg:w-2/6 self-center m-4">
              <img className="rounded-lg" src={props.image} />
          </div>
          <div className="flex flex-wrap justify-center items-center w-full lg:w-4/6">
          {props.children}
          </div>
        </div>
}

ImageText.defaultProps = {
    image: `https://picsum.photos/1200/500`
  };

ImageText.propTypes = {
image: PropTypes.string,
children: PropTypes.node.isRequired
};