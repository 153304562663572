import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";

export default function UiLink(props) {
  return<div className="flex flex-row items-center relative hover:bg-blue-200 transition-colors duration-300 ease-out">
            <div className="h-16 w-16 items-center p-5">
              <img className="h-6 w-6 mhw-6" src={props.image}></img>
            </div>
            <div className="flex flex-col p-4 pl-2">
              <span className="font-semibold text-base">{props.headerText}</span>
              <span className="font-semibold text-sm text-gray-600">{props.descriptionText}</span>
            </div>
            {props.link.startsWith("http") ? <ExtLink link={props.link}></ExtLink> : <IntLink link={props.link}></IntLink>}
        </div>
}

function ExtLink(props) {
  return<a className="absolute top-0 right-0 bottom-0 left-0" href={props.link}></a>
}

function IntLink(props) {
  return<Link
  to={props.link}
  className="absolute top-0 right-0 bottom-0 left-0"
/>
}

UiLink.defaultProps = {
    headerText: `Tady nic není 😧`,
    descriptionText: ``,
    link: `/`,
    image: `https://picsum.photos/1200/500`
  };

  UiLink.propTypes = {
    headerText: PropTypes.string,
    descriptionText: PropTypes.string,
    link: PropTypes.string,
    image: PropTypes.string
  };
