import React from "react";
import PropTypes from "prop-types";

export default function MainHeading(props) {
  return<div className="flex flex-col-reverse text-center w-full mb-4">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">{props.heading}</h1>
            <span className="uppercase text-xs text-blue-600 tracking-widest font-medium title-font mb-1">{props.section}</span>
            {props.children}
        </div>
}

MainHeading.defaultProps = {
    heading: `heading`,
    section: ``
};

MainHeading.propTypes = {
    heading: PropTypes.string,
    section: PropTypes.string,
    children: PropTypes.node.isRequired
};